<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content">
      <!-- <div class="dispflex" style="align-items: baseline">
        <h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
                 <button
                    type="button"
                    id="prevBtn"
                    class="backbtn"
                   @click="previousRoute"
                  >
                   <i class="fa fa-arrow-left"></i>
                  </button>
      </div> -->
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Fees Management
       </h4>
      </div>
    
      <div class="row mt-2"> 
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="widjet m-2">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 12px"
                  ><i class="fas fa-receipt"></i
                ></span>
                <h1>Fee Summary</h1>
              </div>
              <!-- <span class="has-float-label">
                <input
                         disabled
                          type="text"
                          name="asgntDetailsname"
                          class="form-control form-input"
                          id="asgntDetailsname"
                         
                          v-model="viewTotalPaidAmount"
                        />
                  
                    <label for="clslistid" style="color: rgb(18, 237, 18);">Total Paid Amount</label>
              </span> -->
              <!-- <span class="has-float-label">
                <input
                         disabled
                          type="text"
                          name="asgntDetailsname"
                          class="form-control form-input"
                          id="asgntDetailsname"
                         
                          v-model="viewTotalPendingAmount"
                        />
                  
                    <label for="clslistid" style="color: red;">Total Due Amount</label>
              </span> -->
                <!-- <span class="has-float-label">
                   <select
										class="form-control form-input"
										id="deptlistid"
									  v-model="filterValue"
                    @change="applyFilter"
										>
										<option :value=null>-- Select --</option>
										<option v-for="(item,index) in statusList" :key="index" :value="item">
											{{ item }}
										</option>
										</select>
                    <label for="clslistid">Select Status </label>
              </span> -->
              <span class="has-float-label">
                <!-- <button
                    type="button"
                    id="svbtn"
                    style="width: 100%;"
                    class="btn btnsml"
                    :disabled="!filterValue"
                   @click="openReminderAllPopUp"
                  >
                  Send Reminder
                  </button> -->
              </span>
              <span class="has-float-label">
                  
              </span>
              <div class="mt-1 flexitm" style="right: 0px;
    margin-right: 18px;
    position: absolute;
    top: 76px;">
                 <button v-if="currentTab == 1"
                    type="button"
                    id="svbtn"
                    style="width: 100%;"
                    class="btn btnsml"
                    :disabled="!checkOverDueRemainderSelect()"
                   @click="openReminderAllPopUp('due',overDueFeePlanList)"
                  >
                  Send Reminder
                  </button>
                  <button v-if="currentTab == 2"
                    type="button"
                    id="svbtn"
                    style="width: 100%;"
                    class="btn btnsml"
                   @click="openReminderAllPopUp('notPaid',notPaidFeePlanList)"
                  >
                  Send Reminder
                  </button>
              </div>
            </div>
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input
                  type="radio"
                  name="tabs"
                  @click="setActiveTab(1)"
                  id="classesSection"
                  :checked="currentTab == 1 ? 'checked' : ''"
                />
                <label for="classesSection" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Overdue {{ overDueFeePlanList.length }}/{{  feesPlanList.length }}</label>

                <div class="tab">
                  <div class="restable" >
                    <div class="resrow resheader" style="background: #b4c5e9;">
                      <div class="cell"> <label class="custcheckbox">
                            <input type="checkbox" v-model="selectedAllOverdue" @input="selectAllOverDue(selectedAllOverdue)" />
                            <span class="checkmarkchk" ></span>
                          </label></div>
                      <div class="cell" style="padding-left: 16px;">Student Name</div>
                      <div class="cell">Terms</div>
                      <div class="cell">Paid Amount</div>
                      <div class="cell">Pending Amount</div>
                      <div class="cell">Total Amount</div>
                      <div class="cell">Status</div>
                      <div class="cell">Action</div>
                    </div>
                    <div class="resrow" 
                      v-for="(plan, index) in overDueFeePlanList"
                      :key="index"
                    >
                    <div class="cell chkbox" data-title="Select">
                          <label class="custcheckbox">
                            <input type="checkbox" v-model="plan.isSelected"  />
                            <span class="checkmarkchk"></span>
                          </label>
                        </div>
                     
                      <div  v-if="plan.student && plan.student._id" data-title="Plan Name" class="cell">
                         <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${plan.student._id}`)">{{ plan.student.firstName + " " + plan.student.lastName }} </a>
                       </div>

					 <div  class="cell"  data-title="Status">
                         {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getFullyPaidDetails(plan.studentSummaryDetails,plan.student._id) : '-' }}
                     </div>

                     <div  class="cell"  data-title="Status">
                        $ {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getPayMentDetails('paid',plan.studentSummaryDetails,plan.student._id) : '0' }}
                     </div>
                     <div  class="cell"  data-title="Status">
                        $ {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getPayMentDetails('pending',plan.studentSummaryDetails,plan.student._id) : '0' }}
                     </div>
                     <div  class="cell"  data-title="Status">
                         $ {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getPayMentDetails('total',plan.studentSummaryDetails,plan.student._id) : '0' }}
                     </div>

                     <div  class="cell"  :style="(plan.status == 'Not Paid' || plan.status == 'Cancelled' ) ? 'color : darkorange;' : 'color : lightgreen'" data-title="Status">{{ plan.status }}</div>
                     
                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Action">
                        <div class="dispflex" style="justify-content:stretch">
                          <b-dropdown id="dropdown-1" text="" right  style="margin-right:3px">
                            <b-dropdown-item :disabled="plan.status == 'Full Paid' || plan.status == 'Cancelled'" @click.prevent="viewStudentTerms(plan.student._id,plan.academicYearStudent)">Record Payment</b-dropdown-item>
                            <b-dropdown-item :disabled="plan.status == 'Full Paid' || plan.status == 'Cancelled'" @click.prevent="viewEditStudentInvoiceTerms(plan.student._id,plan.academicYearStudent)">Edit Invoice Summary</b-dropdown-item>
                            <b-dropdown-item :disabled="plan.status == 'Full Paid' || plan.status == 'Cancelled'" @click.prevent="openCancelInvoicePopUp(plan.student._id)" >Cancel Invoice</b-dropdown-item>
                            <b-dropdown-item :disabled="plan.status == 'Full Paid' || plan.status == 'Cancelled'" @click.prevent="openReminderPopUp(plan.student._id,plan.student.parentId,'due')">Send a Reminder</b-dropdown-item>
                          </b-dropdown>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  :checked="currentTab == 2 ? 'checked' : ''"
                  @click="setActiveTab(2)"
                  id="assignedClass"
                />
                <label for="assignedClass" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Not Paid  {{ notPaidFeePlanList.length }}/{{  feesPlanList.length }}</label>
                <div class="tab">
                  <div class="restable" >
                    <div class="resrow resheader" style="background: #b4c5e9;">
                      <div class="cell"> <label class="custcheckbox">
                            <input type="checkbox" v-model="selectedAll" @input="selectAll(selectedAll)" />
                            <span :class=" !selectedAll ? 'checkmarkchk' : 'checkmarkchk'"  ></span>
                          </label></div>
                      <div class="cell" style="padding-left: 16px;">Student Name</div>
                      <div class="cell">Terms</div>
                      <div class="cell">Paid Amount</div>
                      <div class="cell">Pending Amount</div>
                      <div class="cell">Total Amount</div>
                      <div class="cell">Status</div>
                      <div class="cell">Action</div>
                    </div>
                    <div class="resrow" 
                      v-for="(plan, index) in notPaidFeePlanList"
                      :key="index"
                    >
                    <div class="cell chkbox" data-title="Select">
                          <label class="custcheckbox">
                            <input type="checkbox" v-model="plan.isSelected"  />
                            <span class="checkmarkchk"></span>
                          </label>
                        </div>
                     
                      <div  v-if="plan.student && plan.student._id" data-title="Plan Name" class="cell">
                         <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${plan.student._id}`)">{{ plan.student.firstName + " " + plan.student.lastName }} </a>
                       </div>

					 <div  class="cell"  data-title="Status">
                         {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getFullyPaidDetails(plan.studentSummaryDetails,plan.student._id) : '-' }}
                     </div>

                     <div  class="cell"  data-title="Status">
                        $ {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getPayMentDetails('paid',plan.studentSummaryDetails,plan.student._id) : '0' }}
                     </div>
                     <div  class="cell"  data-title="Status">
                        $ {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getPayMentDetails('pending',plan.studentSummaryDetails,plan.student._id) : '0' }}
                     </div>
                     <div  class="cell"  data-title="Status">
                         $ {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getPayMentDetails('total',plan.studentSummaryDetails,plan.student._id) : '0' }}
                     </div>

                     <div  class="cell"  :style="(plan.status == 'Not Paid' || plan.status == 'Cancelled' ) ? 'color : darkorange;' : 'color : lightgreen'" data-title="Status">{{ plan.status }}</div>
                     
                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Action">
                        <div class="dispflex" style="justify-content:stretch">
                          <b-dropdown id="dropdown-1" text="" right  style="margin-right:3px">
                            <b-dropdown-item :disabled="plan.status == 'Full Paid' || plan.status == 'Cancelled'" @click.prevent="viewStudentTerms(plan.student._id,plan.academicYearStudent)">Record Payment</b-dropdown-item>
                            <b-dropdown-item :disabled="plan.status == 'Full Paid' || plan.status == 'Cancelled'" @click.prevent="viewEditStudentInvoiceTerms(plan.student._id,plan.academicYearStudent)">Edit Invoice Summary</b-dropdown-item>
                            <b-dropdown-item :disabled="plan.status == 'Full Paid' || plan.status == 'Cancelled'" @click.prevent="openCancelInvoicePopUp(plan.student._id)" >Cancel Invoice</b-dropdown-item>
                            <b-dropdown-item :disabled="plan.status == 'Full Paid' || plan.status == 'Cancelled'" @click.prevent="openReminderPopUp(plan.student._id,plan.student.parentId,'notpaid')">Send a Reminder</b-dropdown-item>
                          </b-dropdown>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  :checked="currentTab == 3 ? 'checked' : ''"
                  @click="setActiveTab(3)"
                  id="fullPaid"
                />
                <label for="fullPaid" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Full Paid  {{ fullPaidFeePlanList.length }}/{{  feesPlanList.length }}</label>
                <div class="tab">
                  <div class="restable" >
                    <div class="resrow resheader" style="background: #b4c5e9;">
                      <!-- <div class="cell">
                         <label class="custcheckbox">
                            <input type="checkbox" v-model="selectedAll" @input="selectAll(selectedAll)" />
                            <span :class=" !selectedAll ? 'checkmarkchk' : 'checkmarkchk'"  ></span>
                          </label>
                        </div> -->
                      <div class="cell" style="padding-left: 16px;">Student Name</div>
                      <div class="cell">Terms</div>
                      <div class="cell">Paid Amount</div>
                      <div class="cell">Pending Amount</div>
                      <div class="cell">Total Amount</div>
                      <div class="cell">Status</div>
                    </div>
                    <div class="resrow" 
                      v-for="(plan, index) in fullPaidFeePlanList"
                      :key="index"
                    >
                    <!-- <div class="cell chkbox" data-title="Select">
                          <label class="custcheckbox">
                            <input type="checkbox" v-model="plan.isSelected"  />
                            <span class="checkmarkchk"></span>
                          </label>
                        </div> -->
                     
                      <div  v-if="plan.student && plan.student._id" data-title="Plan Name" class="cell">
                         <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${plan.student._id}`)">{{ plan.student.firstName + " " + plan.student.lastName }} </a>
                       </div>

					 <div  class="cell"  data-title="Status">
                         {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getFullyPaidDetails(plan.studentSummaryDetails,plan.student._id) : '-' }}
                     </div>

                     <div  class="cell"  data-title="Status">
                        $ {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getPayMentDetails('paid',plan.studentSummaryDetails,plan.student._id) : '0' }}
                     </div>
                     <div  class="cell"  data-title="Status">
                        $ {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getPayMentDetails('pending',plan.studentSummaryDetails,plan.student._id) : '0' }}
                     </div>
                     <div  class="cell"  data-title="Status">
                         $ {{ plan.studentSummaryDetails && plan.studentSummaryDetails.length > 0 ? getPayMentDetails('total',plan.studentSummaryDetails,plan.student._id) : '0' }}
                     </div>

                     <div  class="cell"  :style="(plan.status == 'Not Paid' || plan.status == 'Cancelled' ) ? 'color : darkorange;' : 'color : lightgreen'" data-title="Status">{{ plan.status }}</div>
                     
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
     
    </div>
    <b-modal id="showSummaryPopUp" no-close-on-backdrop no-close-on-esc>
      <div style="margin-bottom: 5%;">
        <div class="form-row" style="width: 100%">
          <div class="form-group input-group col-md-7" style="margin: 0">
            <!-- <span class="close">&times;</span> -->
            <h2>Term Fee Summary List</h2>
          </div>
          <!-- <div class="form-group input-group col-md-2 no-print">
            <a
              style="padding-left: 6px;"
              href="javascript:void(0);"
              @click.prevent="openSiblingDisountPopUp"
              v-if="!studentSibilingDetails.isSiblingDiscountApplied"
              >Eligible for Sibling Discount
            </a>
          </div> -->
          <div class="form-group input-group col-md-3 no-print">
           
            <button
              @click.prevent="closeSummaryPopUp"
              type="button"
              class="btn btncl clsmdl ml-3"
              id="clbtn"
            >
                 <span>Close</span>
            </button>
          </div>
        </div>
        <div
          class="form-row"
          v-if="
            studentSibilingDetails &&
              studentSibilingDetails.siblings &&
              studentSibilingDetails.isSiblingDiscountApplied
          "
          style="width: 100%;margin-top: 2%;margin-bottom: 2%"
        >
          <div class="form-group input-group col-md-4" style="margin: 0">
            <span class="has-float-label">
              <input
                disabled
                :value="studentSibilingDetails.siblings ? 'Yes' : 'No'"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Eligible for Sibling Discount </label>
            </span>
          </div>
          <div class="form-group input-group col-md-4" style="margin: 0">
            <span class="has-float-label">
              <input
                disabled
                v-model="studentSibilingDetails.siblingDiscount"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Discount</label>
            </span>
          </div>
          <div class="form-group input-group col-md-4" style="margin: 0">
            <span class="has-float-label">
              <v-select
                class="form-control form-input"
                disabled
                label="label"
                name="AssignClasses"
                multiple
                v-model="studentSibilingDetails.toWards"
                :options="towardsAllList"
                :reduce="(label) => label.value"
              ></v-select>
              <label for="stadrid">Towards</label>
            </span>
          </div>
        </div>

        <br />
        <div class="contact-form">
          <div v-if="Object.keys(studentBillingSummary).length > 0">
            <div class="admintbl pb-3 mt-1">
              <div>
                <div class="row cust-bg" style="border: 1px solid #e9dede;">
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Select</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Term Name</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Fees Type</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Invoice Date</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Invoice Due Date</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Billing Frequency</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Discount</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Fee Amount ($)</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Penalty</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Status</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Paid Date</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Total Amount ($)</div>
                </div>
                <div
                  v-for="(data, index) in Object.keys(studentBillingSummary)"
                  :key="index"
                >
                  <div
                    class="row cust-lr"
                    :style="studentBillingSummary[data][data][0].status == 'Paid' ? 'color : lightgreen;background-color: darkgray;' : 'color : darkorange'"
                  >
                    <div class="cell chkbox col-1" data-title="Select">
                      <label
                        class="custcheckbox"
                        :style="(studentBillingSummary[data][data][0].status == 'Paid'|| studentBillingSummary[data][data][0].status == 'Cancelled') ? 'cursor: no-drop;' : '' "
                      >
                        <input
                          type="checkbox"
                          checked="checked"
                          v-if="studentBillingSummary[data][data][0].status == 'Paid'"
                          disabled
                        />

                        <input
                          type="checkbox"
                          v-else
                          @change="checkSelected(data,studentBillingSummary[data].isSelected,index)"
                          :disabled="studentBillingSummary[data][data][0].status == 'Paid' || studentBillingSummary[data][data][0].status == 'Cancelled'"
                          v-model="studentBillingSummary[data].isSelected"
                        />
                        <span
                          class="checkmarkchk"
                          :style="
                            (studentBillingSummary[data][data][0].status == 'Paid' || studentBillingSummary[data][data][0].status == 'Cancelled' ) ? 'background-color: #e0d0c3;': '' "
                        ></span>
                      </label>
                    </div>
                    <div class="col-6 cell" style="font-weight: bold;">
                      {{ data }}
                    </div>
                    <div
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      $
                      {{ totalFeesTermAmountOnly(data) | currencyFormat }}
                    </div>
                    <div
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      $
                      {{ totalFeesTermPenality(data) | currencyFormat }}
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;font-size: smaller;">
                      {{ studentBillingSummary[data][data][0].status ? studentBillingSummary[data][data][0].status : "-" }}
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ studentBillingSummary[data][data][0].paidDate ? showDateFormat(studentBillingSummary[data][data][0].paidDate ) : "-" }}
                    </div>
                    <div
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      {{ totalFeesTerm(data) | currencyFormat }}
                    </div>
                  </div>
                  <div
                    class="row cust-lr"
                    v-for="(ter, issndex) in studentBillingSummary[data][data]"
                    :key="issndex"
                  >
                    <div class="col-2 cell"></div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">{{ ter.feeType ? ter.feeType.name : '' }}</div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.feesStartDate | dateFormat }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.feesEndDate | dateFormat }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.billingFrequency }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.isDiscount ? getdiscountAmountTerm(ter.amount) : "0" }} 
                      <!-- {{ ter.isRefundable ? "yes" : "No" }} -->
                    
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.amount | currencyFormat }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      -
                     
                    </div>
                    <div class="col-1 cell" style="font-size: smaller;border: 1px solid #e9dede;">{{ ter.status }}</div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.paidDate ? showDateFormat(ter.paidDate) : "-" }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;"></div>
                  </div>
                </div>
                <div style="border-style: groove;margin-top: 2%;">
                  <div class="row">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Total Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ totalSumAmount | currencyFormat }}
                    </div>
                  </div>
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Discount Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ getDiscountAmount }}
                    </div>
                  </div>
                   <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Penalty Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ getPenaltyAmountOnly }}
                    </div>
                  </div>
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Sibling Discount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      <input
                        v-model="adminOverrideDiscount"
                        type="text"
                        name="stadr"
                        
                        id="stadrid"
                      />
                    </div>
                  </div>
                  <div class="row cust-lr">
                    <div class="col-8 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-3 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Grand Total Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ grandTotalAmount | currencyFormat }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="alert alert-info mt-3" style="padding: 0.35rem 0.65rem">
              <ul style="margin: 0">
                <li>
                  {{
                    planDetails.discount && planDetails.discount.mode
                      ? planDetails.discount.mode == "Rs($)"
                        ? "Rs" + " " + planDetails.discount.discountAmountValue
                        : planDetails.discount.discountPercentageValue + "%"
                      : "0%"
                  }}
                  discount is applicable if paid initially for full term within
                  the due date
                </li>
                <li>
                  {{
                    planDetails.penalty && planDetails.penalty.mode
                      ? planDetails.penalty.mode == "Rs($)"
                        ? "Rs" + " " + planDetails.penalty.penaltyAmountValue
                        : planDetails.penalty.penaltyPercentageValue + "%"
                      : "0%"
                  }}
                  Late Fee charges are levied if the fees are not paid within
                  the due date
                </li>
                <li>
                  Sibling Discount will be applicable if the student is eligible
                  and it will reflect in Student Fee Summary Section
                </li>
                <li>
                  *Discount & other charges are applied/included in Final
                  Billing Summary
                </li>
              </ul>
            </div>
            <div class="widjet mt-4 w-100" v-if="checkTermPayment">
              <div class="widjethdr dispflex">
                <div style="width:auto">
                  <span class="iconsect blubg" style="padding-left:9px;"
                    ><i class="far fa-list-alt"></i
                  ></span>
                  <h1>Payment</h1>
                </div>
              </div>

              <div
                class="widjetcontent p-3"
                style="background-color: darkgrey;"
              >
                <div class="form-row">
                  <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                    <span class="has-float-label">
                      <select
                        class="form-control form-input"
                        name="PlanType"
                        @change="clearValues(paymentDetails.type)"
                        v-model="paymentDetails.type"
                      >
                        <option :value="null">--Select--</option>
                        <option
                          v-for="(item, index) in payMenttypes"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                      <label for="paymenttypelistid"
                        >Payment Type <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Cheque'"
                  >
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="chqno"
                        v-model="paymentDetails.chequeNo"
                        class="form-control form-input"
                        id="chqnoid"
                        placeholder="Cheque No."
                      />
                      <label for="chqnoid"
                        >Cheque No. <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Demand Draft'"
                  >
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="ddno"
                        v-model="paymentDetails.demandDraft"
                        class="form-control form-input"
                        id="ddnoid"
                        placeholder="Demand Draft"
                      />
                      <label for="ddnoid"
                        >Demand Draft <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Cash'"
                  >
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="ddno"
                        v-model="paymentDetails.amount"
                        class="form-control form-input"
                        id="ddnoid"
                        placeholder="Amount"
                      />
                      <label for="ddnoid"
                        >Amount <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div class="form-group input-group col-md-3 mt-3">
                    <button
                      @click.prevent="paymentConfirm"
                      type="button"
                      :disabled="!paymentDetails.type || (paymentDetails.type == 'Cheque' && !paymentDetails.chequeNo) || (paymentDetails.type == 'Demand Draft' && !paymentDetails.demandDraft) || (paymentDetails.type == 'Cash' && !paymentDetails.amount)"
                      class="btn btnsml"
                      id="svbtn"
                    >
                      <i class="fas fa-check-circle"></i> <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="showEditSummaryPopUp" no-close-on-backdrop no-close-on-esc>
      <div>
     <form data-vv-scope="crtfeeplanValidate" id="crtfeeplans" accept-charset="utf-8">  
      <div style="margin-bottom: 5%;">
        <div class="form-row" style="width: 100%">
          <div class="form-group input-group col-md-7" style="margin: 0">
            <!-- <span class="close">&times;</span> -->
            <h2>Edit Invoice Term Amount</h2>
          </div>
          
          <div class="form-group input-group col-md-5 no-print">
           <button 
                type="button"
                class="btn btnsml"
                @click="updateTermAmount"
              
            >
                <span>Update</span>
            </button>
            <button
              @click.prevent="closeEditSummaryPaymentPopUp"
              type="button"
              class="btn btncl clsmdl ml-3"
              id="clbtn"
            >
                 <span>Close</span>
            </button>
          </div>
        </div>
       
        <br />
        <div class="contact-form">
          <div v-if="Object.keys(studentBillingSummary).length > 0">
            <div class="admintbl pb-3 mt-1">
              <div>
                <div class="row cust-bg">
                  <div class="col-1 cell">Term Name</div>
                  <div class="col-1 cell">Fees Type</div>
                  <div class="col-1 cell">Invoice Date</div>
                  <div class="col-1 cell">Invoice Due Date</div>
                  <div class="col-1 cell">Billing Frequency</div>
                  <div class="col-1 cell">Refundable</div>
                  <div class="col-1 cell">Fee Amount ($)</div>
                  <div class="col-1 cell">Penalty</div>
                  <div class="col-1 cell">Status</div>
                  <div class="col-1 cell">Paid Date</div>
                  <div class="col-1 cell">Total Amount ($)</div>
                </div>
                <div
                  v-for="(data, index) in Object.keys(studentBillingSummary)"
                  :key="index"
                >
                  <div
                    class="row cust-lr"
                    :style="studentBillingSummary[data][data][0].status == 'Paid' ? 'color : lightgreen;background-color: darkgray;' : 'color : darkorange'"
                  >
                    
                    <div class="col-6 cell" style="font-weight: bold;">
                      {{ data }}
                    </div>
                    <div v-if="studentBillingSummary[data][data][0].status == 'Paid'"
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      $
                      {{ totalFeesTermAmountOnly(data) | currencyFormat }}
                    </div>
                    <div v-else
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      $
                      {{ EditTotalFeesTermAmountOnly(data) | currencyFormat }}
                    </div>
                    <div
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      $
                      {{ totalFeesTermPenality(data) | currencyFormat }}
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;font-size: smaller;">
                      {{ studentBillingSummary[data][data][0].status ? studentBillingSummary[data][data][0].status : "-" }}
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ studentBillingSummary[data][data][0].paidDate ? showDateFormat(studentBillingSummary[data][data][0].paidDate ) : "-" }}
                    </div>
                    <div v-if="studentBillingSummary[data][data][0].status == 'Paid'"
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      {{ totalFeesTerm(data) | currencyFormat }}
                    </div>
                    <div v-else
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      {{ EditTotalFeesTerm(data) | currencyFormat }}
                    </div>
                  </div>
                  <div
                    class="row cust-lr"
                    v-for="(ter, issndex) in studentBillingSummary[data][data]"
                    :key="issndex"
                  >
                    <div class="col-1 cell"></div>
                    <div class="col-1 cell">{{ ter.feeType ? ter.feeType.name : '' }}</div>
                    <div class="col-1 cell">
                      {{ ter.feesStartDate | dateFormat }}
                    </div>
                    <div class="col-1 cell">
                      {{ ter.feesEndDate | dateFormat }}
                    </div>
                    <div class="col-1 cell">
                      {{ ter.billingFrequency }}
                    </div>
                    <div class="col-1 cell">
                      {{ ter.isRefundable ? "yes" : "No" }}
                    </div>
                    <div class="col-1 cell">
                        <input
                            min="1" 
                            :disabled="ter.status == 'Paid'"
                            v-model="ter.amount"
                            type="number"
                            v-validate="ter.status != 'Paid' ? 'required|numeric|min:1' : false"
                            :name="index + issndex + 'feeAmount'" 
                            style="border: 1px solid #bc8181;"
                            class="form-control form-input"
                        />
                        <span class="required" v-if="errors.first(`crtfeeplanValidate.${index + issndex + 'feeAmount'}`)"> Amount is required and minimum amount 1</span>
                     
                    </div>
                    <div class="col-1 cell">
                      -
                     
                    </div>
                    <div class="col-1 cell" style="font-size: smaller;">{{ ter.status }}</div>
                    <div class="col-1 cell">
                      {{ ter.paidDate ? showDateFormat(ter.paidDate) : "-" }}
                    </div>
                  </div>
                </div>
                <!-- <div style="border-style: groove;margin-top: 2%;">
                  <div class="row">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Total Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ totalSumAmount | currencyFormat }}
                    </div>
                  </div>
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Discount Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ getDiscountAmount }}
                    </div>
                  </div>
                   <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Penalty Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ getPenaltyAmountOnly }}
                    </div>
                  </div>
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Sibling Discount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      <input
                        v-model="adminOverrideDiscount"
                        type="text"
                        name="stadr"
                        class="form-control form-input"
                        id="stadrid"
                      />
                    </div>
                  </div>
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Grand Total Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ grandTotalAmount | currencyFormat }}
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- <div class="alert alert-info mt-3" style="padding: 0.35rem 0.65rem">
              <ul style="margin: 0">
                <li>
                  {{
                    planDetails.discount && planDetails.discount.mode
                      ? planDetails.discount.mode == "Rs($)"
                        ? "Rs" + " " + planDetails.discount.discountAmountValue
                        : planDetails.discount.discountPercentageValue + "%"
                      : "0%"
                  }}
                  discount is applicable if paid initially for full term within
                  the due date
                </li>
                <li>
                  {{
                    planDetails.penalty && planDetails.penalty.mode
                      ? planDetails.penalty.mode == "Rs($)"
                        ? "Rs" + " " + planDetails.penalty.penaltyAmountValue
                        : planDetails.penalty.penaltyPercentageValue + "%"
                      : "0%"
                  }}
                  Late Fee charges are levied if the fees are not paid within
                  the due date
                </li>
                <li>
                  Sibling Discount will be applicable if the student is eligible
                  and it will reflect in Student Fee Summary Section
                </li>
                <li>
                  *Discount & other charges are applied/included in Final
                  Billing Summary
                </li>
              </ul>
            </div>
            <div class="widjet mt-4 w-100" v-if="checkTermPayment">
              <div class="widjethdr dispflex">
                <div style="width:auto">
                  <span class="iconsect blubg" style="padding-left:9px;"
                    ><i class="far fa-list-alt"></i
                  ></span>
                  <h1>Payment</h1>
                </div>
              </div>

              <div
                class="widjetcontent p-3"
                style="background-color: darkgrey;"
              >
                <div class="form-row">
                  <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                    <span class="has-float-label">
                      <select
                        class="form-control form-input"
                        name="PlanType"
                        @change="clearValues(paymentDetails.type)"
                        v-model="paymentDetails.type"
                      >
                        <option :value="null">--Select--</option>
                        <option
                          v-for="(item, index) in payMenttypes"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                      <label for="paymenttypelistid"
                        >Payment Type <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Cheque'"
                  >
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="chqno"
                        v-model="paymentDetails.chequeNo"
                        class="form-control form-input"
                        id="chqnoid"
                        placeholder="Cheque No."
                      />
                      <label for="chqnoid"
                        >Cheque No. <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Demand Draft'"
                  >
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="ddno"
                        v-model="paymentDetails.demandDraft"
                        class="form-control form-input"
                        id="ddnoid"
                        placeholder="Demand Draft"
                      />
                      <label for="ddnoid"
                        >Demand Draft <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Cash'"
                  >
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="ddno"
                        v-model="paymentDetails.amount"
                        class="form-control form-input"
                        id="ddnoid"
                        placeholder="Amount"
                      />
                      <label for="ddnoid"
                        >Amount <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div class="form-group input-group col-md-3 mt-3">
                    <button
                      @click.prevent="paymentConfirm"
                      type="button"
                      :disabled="!paymentDetails.type || (paymentDetails.type == 'Cheque' && !paymentDetails.chequeNo) || (paymentDetails.type == 'Demand Draft' && !paymentDetails.demandDraft) || (paymentDetails.type == 'Cash' && !paymentDetails.amount)"
                      class="btn btnsml"
                      id="svbtn"
                    >
                      <i class="fas fa-check-circle"></i> <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      </form>
       </div>
    </b-modal>
     <b-modal id="sendReminderPopup" no-close-on-backdrop
      no-close-on-esc >
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Send a reminder</h2>
        <br />
        Are sure sure you want to send a reminder ?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
          @click="sendReminderConfirm"
            type="button"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Yes</span>
          </button>
          <button @click.prevent="closeReminderPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>No</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
     </b-modal>
     <b-modal id="sendReminderALlPopup" no-close-on-backdrop
      no-close-on-esc >
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Send a reminder</h2>
        <br />
        Are sure sure you want to send a reminder ?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
          @click="sendReminderAllConfirm()"
            type="button"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Yes</span>
          </button>
          <button @click.prevent="closeReminderAllPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>No</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
     </b-modal>
      <b-modal id="cancelInvoicePopup" no-close-on-backdrop
      no-close-on-esc >
      <button type="button" class="close"  @click.prevent="closeCancelInvoicePopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Cancel Invoice</h2>
        <br />
        Are sure do you want to cancel the Invoice ?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            @click.prevent="cancelInvoiceConfirm"
            type="button"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Yes</span>
          </button>
          <button @click.prevent="closeCancelInvoicePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>No</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
     </b-modal>
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

export default {
  name: "vi-fees-management",
  data() {
    return {
     
      count: {
        feePlanCount : 0,
        feePlanApprovedCount : 0,
        assignFeeTypesCount : 0,
        discountsAndPenaltiesCount : 0,
        assignFeeToStudentsCount : 0
      },
      paymentDetails: {
        type: null,
        chequeNo: "",
        demandDraft: "",
        amount: null,
        terms: [],
      },
      selectedAll: false,
      selectedAllOverdue: false,
      filterValue: null,
      statusList: ['Not Paid','Partial Paid','Full Paid'],
      //Cancelled
      adminOverrideDiscount: 0,
      totalSumAmount: 0,
      totalPenaltyAmount: 0,
      grandTotalAmount: 0,
      discountAmount: 0,
      planDetails: {},
      studentSibilingDetails: {},
      payMenttypes: ["Cash", "Cheque", "Demand Draft"],
      studentBillingSummary: {},
      searchWords: '',
      skip: 0, // set the skip info
      limit: 10, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: 0,
      academicYearList: [],
      feesPlanList: [],
      overDueFeePlanList: [],
      notPaidFeePlanList: [],
      fullPaidFeePlanList: [],

      totalCountStudents: 0,
      recordPaymentStudentId: null,
      studentAcademicyear: null,
      reminderStudentId: null,
      reminderStudentParentId: null,
      reminderStudentAction: '',
      cancelInvoiceStudentId: null,
      viewTotalPaidAmount : 0,
      viewTotalPendingAmount : 0,
      currentTab: 1,
      overallReminderStudentIds : [],
      overallReminderType : ''

    };
  },
  created() {
    this.getAcademicYearList();
    this.getFeesPlanList();
  },
  computed: {
    styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
    },
    getDiscountAmount() {
      let selectedCount = 0;
      let totalTermCount = Object.values(this.studentBillingSummary).length;
     
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }

      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);
         
        if (selectedList.length == totalTermCount) {
          this.discountAllow = true;
          
          if (selectedList[0].planDiscount.discount) {

            let totalDiscount = 0;

             for (let x of selectedList) {
              const termTotalAmount = this.totalFeesTermDiscountAvaible(x.name);
              totalDiscount = totalDiscount + termTotalAmount;
              }

             
            const getDiscountDetails = selectedList[0].planDiscount.discount;
            if(getDiscountDetails.mode){
              if (getDiscountDetails.mode == "Rs($)") {
                const amountValue = this.totalSumAmount - (Number(getDiscountDetails.discountAmountValue) * totalDiscount );

               // const amountValue = this.totalSumAmount - Number(getDiscountDetails.discountAmountValue);
                this.grandTotalAmount = amountValue + this.totalPenaltyAmount;

                if (this.adminOverrideDiscount > 0) {
                  this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
                }
                return amountValue ? (this.totalSumAmount - amountValue ) : getDiscountDetails.discountAmountValue;

                //return getDiscountDetails.discountAmountValue;
              } else {
                const discountValue = (this.totalSumAmount * (Number(getDiscountDetails.discountPercentageValue) * totalDiscount )) / 100;

                //const discountValue = (this.totalSumAmount * Number(getDiscountDetails.discountPercentageValue)) / 100;
                this.grandTotalAmount = this.totalSumAmount - discountValue;
                 this.grandTotalAmount = this.grandTotalAmount + this.totalPenaltyAmount;
                if (this.adminOverrideDiscount > 0) {
                  this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
                }
                return discountValue;
              }
            }
          }

          this.grandTotalAmount = this.totalSumAmount + this.totalPenaltyAmount;
          
          if (this.adminOverrideDiscount > 0) {
            this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
          }
        } else {
          this.discountAllow = false;
          
          this.grandTotalAmount = this.totalSumAmount + this.totalPenaltyAmount;
          if (this.adminOverrideDiscount > 0) {
            this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
          }
          return 0;
        }
      }
    },
    getTotalAmount() {
      let finalAmount = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);

        if (selectedList.length > 0) {
          for (let x of selectedList) {
            finalAmount = this.totalFeesTerm(x.name) + finalAmount;
          }
          return finalAmount;
        }
      }
    },
    
    getPenaltyAmountOnly() {
      this.totalPenaltyAmount = 0
      
      let finalAmount = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }

      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected && x.status != "Paid" );

        if (selectedList.length > 0) {
          for (let x of selectedList) {
           
            finalAmount = this.totalPenaltyAmountPay(x.name) + finalAmount;
          }
          this.totalPenaltyAmount = finalAmount
          return finalAmount
        } else {
          this.totalPenaltyAmount = 0
          return 0;
        }
      }
    },
    // for(let x of this.notPaidFeePlanList){
    //         x['isSelected'] = false
    //   }

    //   for(let x of this.overDueFeePlanList){
    //         x['isSelected'] = false
    //   }

    
    checkTermPayment() {
      let totalTermCount = Object.values(this.studentBillingSummary).length;
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }

      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.status == "Paid");

        if (selectedList.length == totalTermCount) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
   
    towardsList() {
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
     
      return allTermList
        .filter((x) => x.status != "Paid")
        .map((x) => {
          return {
            label: x.name,
            value: x.name,
          };
        });
    },
    towardsAllList() {
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      return allTermList.map((x) => {
        return {
          label: x.name,
          value: x.name,
        };
      });
    },
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
                   this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = 0,
				           this.countAll = true,
                   this.getFeesPlanList();
              }
          }
  },
  methods: {
    previousRoute(){
      let data = localStorage.getItem('previousTab');
      let data2 = localStorage.getItem('activeTab');
     

        if(data2 == '/feesManagement/home'){
          localStorage.setItem("activeTab",'/feesManagement/home');
          localStorage.setItem("previousTab",'/feesManagement/home');
        }else{
           localStorage.setItem("activeTab",data);
          localStorage.setItem("previousTab",data);
        }
     this.$router.go(-1)
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    showDateFormat(value) {
      if (value) {
        const dtf = new Intl.DateTimeFormat("en", {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        });
        const [
          { value: mo },
          ,
          { value: da },
          ,
          { value: ye },
        ] = dtf.formatToParts(new Date(value));

        return `${da}-${mo}-${ye}`;
      } else {
        return "";
      }
    },
    checkOverDueRemainderSelect() {
        const selectedList = this.overDueFeePlanList.filter((x) => x.isSelected == true);

        if (selectedList.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      totalFeesTermDiscountAvaible(value){
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value].filter( x => x.isDiscount).length
        // return this.studentBillingSummary[value][value]
        //   .map((o) => o.amount + o.penaltyAmount)
        //   .reduce((a, c) => {
        //     return a + c;
        //   });
      }
    },
    checkSelected(term, value, index) {
      Object.values(this.studentBillingSummary).forEach((x, i) => {
        if (value) {
          if (i < index && x.status != "Paid") {
            x.isSelected = true;
          }
        } else {
          if (i > index && x.status != "Paid") {
            x.isSelected = false;
          }
        }
      });
      let finalAmount = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
       
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected && x.status != "Paid" );
          
        if (selectedList.length > 0) {
          for (let x of selectedList) {
            finalAmount = this.totalFeesTermPayOnly(x.name) + finalAmount;
          }
          this.totalSumAmount = finalAmount;
          this.getSibilingDiscountAmount();
        } else {
          this.totalSumAmount = 0;
        }
      }
    },
    totalFeesTerm(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.amount + o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    EditTotalFeesTerm(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => Number(o.amount) + o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
     
      } else {
        return 0;
      }
    },
    totalFeesTermPayOnly(value) {
       if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.amount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    totalPenaltyAmountPay(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    totalFeesTermAmountOnly(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.amount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    EditTotalFeesTermAmountOnly(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => Number(o.amount))
          .reduce((a, c) => {
            return a + c;
          });
       
      } else {
        return 0;
      }
    },
    totalFeesTermPenality(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    getSibilingDiscountAmount() {
      let finalAmount = 0;

      let calculateTermTotalAmount = 0;
      let discountValue = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);

        if (selectedList.length > 0) {
          for (let x of selectedList) {
            if (this.studentSibilingDetails.toWards.indexOf(x.name) != -1) {
              const termTotalAmount = this.totalFeesTerm(x.name);
              discountValue = (termTotalAmount * Number(this.studentSibilingDetails.siblingDiscount)) / 100;
              finalAmount = finalAmount + discountValue;
            }
          }
          this.adminOverrideDiscount = finalAmount;
        }
      }
    },
    clearValues(value) {
      (this.paymentDetails.chequeNo = ""),
        (this.paymentDetails.demandDraft = ""),
        (this.paymentDetails.amount = this.grandTotalAmount);
    },
    closeReminderPopUp(){
      this.$bvModal.hide("sendReminderPopup");
      this.reminderStudentId = null
      this.reminderStudentParentId = null,
      this.reminderStudentAction = ''
    },
    setActiveTab(index) {
      this.currentTab = index;

      this.selectedAll = false,
      this.selectedAllOverdue = false
      for(let x of this.notPaidFeePlanList){
            x['isSelected'] = false
      }

      for(let x of this.overDueFeePlanList){
            x['isSelected'] = false
      }
    },
    closeReminderAllPopUp(){
      this.$bvModal.hide("sendReminderALlPopup");
      this.overallReminderStudentIds = []
   //   this.overallReminderStudentIds = data.filter( x => x.isSelected)
      this.overallReminderType = ''
      // this.reminderStudentId = null
      // this.reminderStudentParentId = null
    },
    
    closeCancelInvoicePopUp(){
     this.$bvModal.hide("cancelInvoicePopup");
     this.cancelInvoiceStudentId = null
    },
    closeSummaryPaymentPopUp() {
      this.recordPaymentStudentId = null;
      this.$bvModal.hide("showSummaryPopUp");
      this.studentBillingSummary = {};
      this.paymentDetails.type = null,
      this.paymentDetails.chequeNo = "",
      this.paymentDetails.demandDraft = "",
      this.paymentDetails.amount = null,
      this.paymentDetails.terms = []
      this.$bvModal.hide("showAdhocFeeSummaryPopUp");
      this.studentAdhocFeesList = [];
      this.getFeesPlanList();
    },
    closeEditSummaryPaymentPopUp() {
      this.recordPaymentStudentId = null;
      this.$bvModal.hide("showEditSummaryPopUp");
      this.studentBillingSummary = {};
      this.paymentDetails.type = null,
      this.paymentDetails.chequeNo = "",
      this.paymentDetails.demandDraft = "",
      this.paymentDetails.amount = null,
      this.paymentDetails.terms = []
      this.$bvModal.hide("showAdhocFeeSummaryPopUp");
      this.studentAdhocFeesList = [];
      this.getFeesPlanList();
    },
    getdiscountAmountTerm(data){
      if(this.planDetails && this.planDetails.discount && this.planDetails.discount.mode){
            if(this.planDetails.discount.mode == 'Rs($)'){
           //  let value = `Rs: ${this.planDetails.discount.discountAmountValue}`
             return this.planDetails.discount.discountAmountValue

            }else{
              return this.planDetails.discount.discountPercentageValue + "%"

            }
          }else{
            return 0
          }
     },
     getFullyPaidDetails(data,studentId){

       const notPaidList = data.filter( x => x.status == 'Paid' &&  x.studentId == studentId)

       const totalCount = data.filter( x => x.studentId == studentId)

       const groupByTerms = totalCount.reduce((r,a) => {
            r[a.term] = [...r[a.term] || [], a];
            return r;
          }, {});

       const groupByPaidTerms = notPaidList.reduce((r,a) => {
            r[a.term] = [...r[a.term] || [], a];
            return r;
          }, {});

       return Object.keys(groupByPaidTerms).length + '/' + Object.keys(groupByTerms).length

    },
    getPayMentDetails(type,data,studentId){

     if(type == 'paid'){

        const notPaidList = data.filter( x => x.status == 'Paid' &&  x.studentId == studentId)

        if(notPaidList.length > 0){
            let totalAmount = 0;

            for (let x of notPaidList) {
                if (x.amount) {
                    totalAmount = totalAmount + x.amount;
                }
            }
            return totalAmount;
        }else{
          return 0
        }
     }

     if(type == 'pending'){

        const notPaidList = data.filter( x => x.status == 'Not Paid' && x.studentId == studentId )

        if(notPaidList.length > 0){
            let totalAmount = 0;

            for (let x of notPaidList) {
                if (x.amount) {
                    totalAmount = totalAmount + x.amount;

                }

            }

            return totalAmount;
        }else{
          return 0
        }
     }

     if(type == 'total'){

        const studentAmountDetails = data.filter( x => x.studentId == studentId )
       
        if(studentAmountDetails.length > 0){
            let totalAmount = 0;

            for (let x of studentAmountDetails) {
                if (x.amount) {
                    totalAmount = totalAmount + x.amount;
                }
            }
            return totalAmount;
        }else{
          return 0
        }
     }
    
    },
    closeSummaryPopUp() {
      this.studentAcademicyear = null
      this.recordPaymentStudentId = null;
      this.$bvModal.hide("showSummaryPopUp");
      this.studentBillingSummary = {};
    },
    async navToSelectedPage(selectedPageNo) {

      this.skip = (this.limit * (selectedPageNo - 1));

      this.countAll = false

      this.getFeesPlanList();
    },
    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getFeesPlanList();
    },
    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getFeesPlanList();
    },
    async updateTermAmount(){
       let userData = secureUI.sessionGet("user");
      let isFormValid = false;

      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtfeeplanValidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid) {
          let termList = []
          let allTermList = []

          for (let x of Object.values(this.studentBillingSummary)) {

            if(x.status == 'Not Paid'){
            termList = this.studentBillingSummary[x.name][x.name];
            allTermList = allTermList.concat(termList)
            }
          }

          for (let x of allTermList) {
           x.amount = Number(x.amount)
          }
           
            if(!userData) {
                this.$toasted.error("Please login and do the action");
            } else {
                const response = await ViService.viXPut(`/feesManagement/updateStudentInvoiceTerms`,allTermList,userData.token);

                if (response.isSuccess) {
                this.closeEditSummaryPaymentPopUp()
                this.$toasted.success(response.message);
                } else {
                this.$toasted.error(response.message);
                 if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
                }
            }
      }else {
          this.$toasted.error(message);
      }

    },
    async viewStudentTerms(studentId,acdYr) {
      this.studentAcademicyear = acdYr
      this.recordPaymentStudentId = studentId;
      await this.getBillingSummaryList();
      this.$bvModal.show("showSummaryPopUp");
    },
    async openReminderPopUp(studentId,parentId,type) {
      this.reminderStudentId = studentId;
      this.reminderStudentParentId = parentId
      this.reminderStudentAction = type
      this.$bvModal.show("sendReminderPopup");
    },
    async openReminderAllPopUp(type,data) {
      this.overallReminderStudentIds = []
      this.overallReminderStudentIds = data.filter( x => x.isSelected)
      this.overallReminderType = type

      // this.reminderStudentId = studentId;
      // this.reminderStudentParentId = parentId
      this.$bvModal.show("sendReminderALlPopup");
    },
    
    async openCancelInvoicePopUp(studentId) {
      this.cancelInvoiceStudentId = studentId;
      this.$bvModal.show("cancelInvoicePopup");
    },
    async viewEditStudentInvoiceTerms(studentId,acdYr) {
      this.studentAcademicyear = acdYr
      this.recordPaymentStudentId = studentId;
      await this.getBillingSummaryList();
      this.$bvModal.show("showEditSummaryPopUp");
    },
    async getBillingSummaryList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/contact/getBillingSummaryList?studentId=${this.recordPaymentStudentId}&feePlanId=${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);

          this.studentBillingSummary = studentDetails.summaryList;
          this.studentSibilingDetails = studentDetails.studentData;
          this.planDetails = studentDetails.planDetails || {};

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async paymentConfirm() {
      const allTermList = [];
      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);

        if (selectedList.length > 0) {
          const terms = selectedList.map((x) => x.name);
          this.paymentDetails.terms = terms;
          this.paymentDetails["actionType"] = 'School Admin'
          this.paymentDetails["razorPayPaid"] = {}
          this.paymentDetails['planDetails'] = this.planDetails
          this.paymentDetails['sibiling'] = this.studentSibilingDetails
          this.updateStudentTerms();
        } else {
          this.$toasted.error("Please select terms");
        }
      }
    },
    async updateStudentTerms() {
      if (this.recordPaymentStudentId) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          

          this.paymentDetails['totalSumAmount'] = this.totalSumAmount
          this.paymentDetails['getDiscountAmount'] = this.getDiscountAmount
          this.paymentDetails['getPenaltyAmountOnly'] = this.getPenaltyAmountOnly
          this.paymentDetails['adminOverrideDiscount'] = this.adminOverrideDiscount
          this.paymentDetails['grandTotalAmount'] = this.grandTotalAmount
          this.paymentDetails['academicYear'] = this.studentAcademicyear
          this.paymentDetails['academicYearValue'] = this.studentAcademicyear
          
          const response = await ViService.viXPut(
            `/feesManagement/updateStudentsTermsAndFeePlan/${this.recordPaymentStudentId}/${this.$route.params.id}/${userData.userDetails.account._id}`,
            this.paymentDetails,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.closeSummaryPaymentPopUp();
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    applyFilter(){
      this.getFeesPlanList()
    },
    async getFeesPlanList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        this.viewTotalPaidAmount = 0
        this.viewTotalPendingAmount = 0
        this.filterValue = ''

        this.fullPaidFeePlanList = []
        this.overDueFeePlanList = []
        this.notPaidFeePlanList = []

        const response = await ViService.viXGet(`/feesManagement/getPlanInvoiceStudentList?planId=${this.$route.params.id}&status=${this.filterValue}`,userData.token);
        
        if (response.isSuccess) {

           const resultData = secureUI.secureGet(response.data);
           
           this.feesPlanList = resultData
           

           if(resultData.length > 0){

            this.fullPaidFeePlanList = resultData.filter( x => x.status == 'Full Paid')
            let dnotPaidFeePlanList = resultData.filter( x => x.status == 'Partial Paid' || x.status == 'Not Paid')
         
           
            for(let x of dnotPaidFeePlanList){
              let details = x.studentSummaryDetails.filter( y => y.studentId == x.student._id && (y.dueDate && new Date(y.dueDate) < new Date() && y.status == 'Not Paid'))
                if(details.length > 0){
                  this.overDueFeePlanList.push(x)
                
                }else{
                  this.notPaidFeePlanList.push(x)
                }
            }

           }
           


          
           if(this.overDueFeePlanList.length > 0){
            for(let x of this.overDueFeePlanList){
              x['isSelected'] = false
            }
            }

            if(this.notPaidFeePlanList.length > 0){
            for(let x of this.notPaidFeePlanList){
              x['isSelected'] = false
            }
            }

           this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    selectAll(value){
      
      if(!value){
        for(let x of this.notPaidFeePlanList){
            x['isSelected'] = true
        }
      }else{
        for(let x of this.notPaidFeePlanList){
            x['isSelected'] = false
        }
      }
     
    },
    selectAllOverDue(value){
      
      if(!value){
        for(let x of this.overDueFeePlanList){
            x['isSelected'] = true
        }
      }else{
        for(let x of this.overDueFeePlanList){
            x['isSelected'] = false
        }
      }
     
    },
    
    async sendReminderConfirm(){
     let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        const response = await ViService.viXGet(`/feesManagement/sendInvoiceReminder?planId=${this.$route.params.id}&studentId=${this.reminderStudentId}&parentId=${this.reminderStudentParentId}&sendAction=${this.reminderStudentAction}`,userData.token);
 
        if (response.isSuccess) {
          this.closeReminderPopUp()
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async sendReminderAllConfirm(){
     let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
          planId: this.overallReminderStudentIds[0].feePlanId,
          planName: this.overallReminderStudentIds[0].planName,
          studentIds: this.overallReminderStudentIds.map( x => x.student._id),
          parentIds: this.overallReminderStudentIds.map( x => x.student.parentId),
          type: this.overallReminderType
        }



        
        const response = await ViService.viXPost("/feesManagement/sendOverAllReminder",obj,userData.token );
 
         if (response.isSuccess) {
           this.closeReminderAllPopUp()
           for(let x of this.notPaidFeePlanList){
            x['isSelected'] = false
           }

           for(let x of this.overDueFeePlanList){
            x['isSelected'] = false
           }

           this.$toasted.success(response.message);
         } else {
           this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                 localStorage.removeItem('user');
                 this.$router.push('/login');
          }
         }
      }
    },
    
    async cancelInvoiceConfirm(){
     let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        const response = await ViService.viXGet(`/feesManagement/cancelInvoiceTerms?planId=${this.$route.params.id}&studentId=${this.cancelInvoiceStudentId}`,userData.token);
 
        if (response.isSuccess) {
          this.closeCancelInvoicePopUp()
          this.getFeesPlanList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    }
  }
};
</script>